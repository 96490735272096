@import 'design-system/styles';

.quill-content {
  @include text-style('text-md');

  color: $neutral-700;

  // Headers
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: $spacing-5xl 0 $spacing-md;
    font-weight: 600;
  }

  h1 {
    @include text-style('display-xs-bold');
  }

  h2 {
    @include text-style('display-2xs-bold');
  }

  h3 {
    @include text-style('text-xl-bold');
  }

  h4 {
    @include text-style('text-lg-bold');
  }

  h5 {
    @include text-style('text-md-bold');
  }

  h6 {
    @include text-style('text-sm-bold');
  }

  // Paragraphs and spacing
  p {
    margin: $spacing-xl 0;
  }

  // Lists
  ul,
  ol {
    margin: $spacing-xl 0;
    padding-left: $spacing-5xl;
  }

  li {
    margin: $spacing-md 0;
  }

  // Links
  a {
    color: $purple-700;
    text-decoration: underline;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }

  // Bold
  strong,
  b {
    font-weight: 600;
  }

  // Blockquotes
  blockquote {
    border-left: 4px solid $neutral-300;
    margin: $spacing-xl 0;
    padding: $spacing-md $spacing-xl;
    color: $neutral-600;
  }

  // Code blocks
  pre {
    background-color: $neutral-100;
    padding: $spacing-xl;
    border-radius: $spacing-md;
    overflow-x: auto;
    margin: $spacing-xl 0;
  }

  code {
    font-family: monospace;
    background-color: $neutral-100;
    padding: $spacing-xxs $spacing-xs;
    border-radius: $spacing-xs;
  }

  // Images
  img {
    max-width: 100%;
    height: auto;
    margin: $spacing-xl 0;
  }

  // Tables
  table {
    border-collapse: collapse;
    width: 100%;
    margin: $spacing-xl 0;
  }

  th,
  td {
    border: 1px solid $neutral-200;
    padding: $spacing-md;
    text-align: left;
  }

  th {
    background-color: $neutral-100;
  }
}
